const theme = {
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                html: {
                    fontFamily: "Noto Sans, Nanum Gothic, Black Han Sans, Comfortaa, Outfit, Robotto, sans-serif"
                },
                body: {
                    margin: "0",
                    fontFamily: "Noto Sans, Nanum Gothic, Black Han Sans, Comfortaa, Outfit, Robotto, sans-serif"
                }
            },
        },
    },
    typography: {
        fontFamily: [
            'Noto Sans KR',
            'Noto Sans',
            'Nanum Gothic',
            'Comfortaa',
            'Outfit',
            'Roboto',
            'sans-serif',
            '-apple-system',
            'BlinkMacSystemFont',
            'Segoe UI',
            'Oxygen',
            'Ubuntu',
            'Cantarell',
            'Fira Sans',
            'Droid Sans',
            'Helvetica Neue',
        ].join(','),
    },
    palette: {
        primary: {
            main: '#212121',
            light: '#8b95b1', // lighter dusty blue
            dark: '#496179', // dark dusty blue
            contrastText: '#fff',
        },
        secondary: {
            main: '#13567A', // dusty teal
            light: '#587B77', // lighter dusty teal
            dark: '#396A75', // dark teal
            contrastText: '#fff',
        },
        error: {
            main: '#FF6781', // coral
            light: '#ffe4e4', // coral (lighter shade)
            dark: '#ffdfdf', // crimson
            contrastText: '#333',
        },
        warning: {
            main: '#fdecb7', // mustard
            light: '#fff9e6', // mustard (lighter shade)
            dark: '#fff6e5', // burnt sienna
            contrastText: '#333',
        },
        info: {
            main: '#E4D7C9', // gold grey
            light: '#f7f6ff', // light purple (lighter shade)
            dark: '#f2f1ff', // deep purple
            contrastText: '#333',
        },
        success: {
            main: '#9DD8C1', // mint
            light: '#D8EAE4', // mint (lighter shade)
            dark: '#e2fbeb', // teal
            contrastText: '#333',
        },
        darkTheme: {
            main: 'white',
        },
    },
};

export default theme