import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import { useAuth } from "../../context/AuthContext";
import { useState } from "react";
import { Google } from "@mui/icons-material";
import { log } from "../../config/firebase";

interface SignUpProps {
    callback: () => void;
}

const SignUpComponent: React.FC<SignUpProps> = ({ callback }) => {
    const { signUpWithEmailAndPassword, signUpWithGoogle } = useAuth();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    const handleEmailSignUp = async () => {
        try {
            await signUpWithEmailAndPassword(email, password);
            log('sign_up', { method: 'email' });
            if (callback) callback();
        } catch (error: any) {
            console.error("Error signing up with email:", error);
            setError(error.message.toString());
            log('sign_up_fail', { method: 'email' });
        }
    };

    const handleGoogleSignUp = async () => {
        try {
            await signUpWithGoogle();
            log('sign_up', { method: 'google' });
            if (callback) callback();
        } catch (error) {
            console.error('Error signing up with Google:', error);
            log('sign_up_fail', { method: 'google' });
        }
    };

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ pt: 7, pb: 7 }} width="300px" maxWidth="100%">
            <Box p={2} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <Typography variant="h6" fontWeight={600}>
                    커리어해커님, 환영합니다!
                </Typography>
                <Typography variant="subtitle2" pt={1}>
                    돈, 시간, 감정으로부터 자유로워지는 방법
                </Typography>
            </Box>
            <Box width="200px" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <TextField
                    label="이메일"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    required
                    fullWidth
                    margin="dense"
                />
                <TextField
                    label="비밀번호"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    required
                    fullWidth
                    margin="dense"
                />
                <Button color="secondary" variant="contained" onClick={handleEmailSignUp} sx={{ m: 1, alignItems: "center" }} fullWidth>
                    무료 회원가입
                </Button>
                {error && (
                    <Typography variant="caption" color="error">
                        {error}
                    </Typography>
                )}
                <Typography variant="caption" p={1}> <b>또는</b> </Typography>
                <Button variant="outlined" startIcon={<Google />} onClick={handleGoogleSignUp} sx={{ m: 1, alignItems: 'center', fontSize: '9px' }} fullWidth>
                    구글 계정으로 가입하기
                </Button>
                {/* <Button variant="outlined" startIcon={<FacebookIcon />} onClick={handleFacebookSignUp} sx={{ m: 1, alignItems: 'center', fontSize: '9px' }} fullWidth >
                    Continue with Facebook
                </Button> */}
            </Box>
            <Divider variant="fullWidth" sx={{ width: '250px', m: 1 }} />
            <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                <Typography variant="caption" sx={{ m: 1 }}>
                    이미 계정이 있으신가요?{" "}
                </Typography>
                <Typography
                    variant="caption"
                    onClick={callback}
                    sx={{ textDecoration: 'underline', cursor: 'pointer', color: 'primary.main' }}>
                    <b>로그인</b>
                </Typography>
            </Box>
        </Box >
    )
}

export default SignUpComponent;

