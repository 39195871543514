import {
    MdSchool, // education
    MdWorkOutline, // productivity, professional_development
    MdChat, // communication
    MdLeaderboard, // leadership
    MdCreate, // creativity, creative, design
    MdOutlineSupervisorAccount, // management
    MdPersonalVideo, // personal_development
    MdFavorite, // relationship
    MdInfoOutline, // information
    MdAttachMoney, // finance
    MdLanguage, // language
    MdComputer, // technology
    MdBook, // education, content_strategy, writing
    MdExplore, // travel
    MdHealthAndSafety, // health
    MdBuild, // development
    MdManageSearch, // marketing
} from 'react-icons/md';

export const getCategoryIcon = (category: string) => {
    const icons = {
        education: MdSchool,
        productivity: MdWorkOutline,
        communication: MdChat,
        leadership: MdLeaderboard,
        creativity: MdCreate,
        management: MdOutlineSupervisorAccount,
        personal_development: MdPersonalVideo,
        relationship: MdFavorite,
        information: MdInfoOutline,
        finance: MdAttachMoney,
        language: MdLanguage,
        marketing: MdManageSearch,
        technology: MdComputer,
        content_strategy: MdBook,
        writing: MdBook,
        creative: MdCreate,
        research: MdBook,
        development: MdBuild,
        market_research: MdExplore,
        professional_development: MdWorkOutline,
        health: MdHealthAndSafety,
        travel: MdExplore,
    };

    // Default to a general information icon if category is not matched
    return icons[category as keyof typeof icons] || MdInfoOutline;
}
