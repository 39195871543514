import React from 'react';
import { Box, Typography, Button, Chip, styled } from '@mui/material';
import { getRandomColor } from '../../utils/colors';
import { formatDistanceToNow } from 'date-fns';
import { ko } from 'date-fns/locale';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import ReactMarkdown from 'react-markdown';
import { formatPromptUsingRegex } from '../../utils/stringFormat';
import { log } from '../../config/firebase';
import { Prompt } from 'types';
import { copyToClipboard } from '../../utils/utils';

const StyledChip = styled(Chip)({
    margin: '2px', // Add margin as needed
    '& .MuiChip-label': {
        fontSize: '10px', // Adjust the font size as needed
        color: 'primary',
    },
    '&:hover': {
        transform: 'scale(1.05)',
    },
});

interface PromptCardProps {
    prompt: Prompt;
}

const PromptCard: React.FC<PromptCardProps> = ({ prompt }) => {
    return (
        <Box m={1}>
            <Typography variant="subtitle1">{prompt.gpt_persona}의</Typography>
            <Typography variant="h6" fontWeight={600}>{prompt.prompt_title}</Typography>
            {/* // only first 5 characters of the uid */}
            {
                prompt.username != null ?
                    <Typography variant="subtitle2" fontWeight={600}>작성자: {prompt.username}<br /></Typography>
                    : prompt.uid && <Typography variant="subtitle2" fontWeight={600}>작성자: {prompt.uid.substring(0, 5)}***<br /></Typography>
            }
            <Typography variant="caption" sx={{ textAlign: 'end' }}>
                {formatDistanceToNow(prompt.updatedAt ? new Date(prompt.updatedAt) : new Date(), { addSuffix: true, locale: ko })}
            </Typography>
            <Box mt={2} mb={2}>
                {prompt.prompt_tags?.map((tag, tagIndex) => (
                    <StyledChip
                        key={tagIndex}
                        label={tag}
                        color="primary"
                        style={{
                            backgroundColor: getRandomColor(),
                            color: 'black',
                        }}
                    />
                ))}
            </Box>
            {/* prompt content */}
            <Typography component="div" variant="body2" mt={2} mb={2}>
                <ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]}>{formatPromptUsingRegex(prompt.prompt)}</ReactMarkdown>
            </Typography>
            {/* TODO: add user feedback: like & comments */}
            {/* Copy Button */}
            <Box display="flex" justifyContent="center" alignItems="center">
                <Button
                    variant="outlined"
                    color="secondary"
                    sx={{ margin: 1 }}
                    onClick={() => {
                        log('prompt_explorer.copy_prompt_button_click', { prompt: prompt.prompt_title })
                        copyToClipboard(formatPromptUsingRegex(prompt.prompt));
                    }}
                >
                    <Typography variant="subtitle2">프롬프트 복사하기</Typography>
                </Button>
            </Box>
        </Box>
    );
};

export default PromptCard;
