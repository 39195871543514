import { Box, Button, Grid, Typography, styled } from "@mui/material";
import { FaSeedling } from "react-icons/fa6";
import React, { useContext, useEffect, useState } from "react";
import { IoMdRefresh } from "react-icons/io";
import { Goal } from "types";
import { PersonasContext } from "../../context/PersonasContext";
import { getCategoryIcon } from "../../utils/icons";
import { log } from "../../config/firebase";

const GoalCardsContainer = styled('div')({
    flexGrow: 1,
    margin: 30,
    padding: '0 20px', // Add some padding for spacing
    textWrap: 'wrap',
    maxWidth: '100%',
});

const GoalCardsComponent = styled('div')({
    flexGrow: 1,
    margin: 10,
    marginTop: 20,
    display: 'flex',
    flexDirection: 'row',
});

const GoalCardComponent = styled('div')({
    cursor: 'pointer', // Makes it clear that the cards are clickable
    margin: 5,
    border: '0.5px solid',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 15,
    ':hover': {
        backgroundColor: '#C2DCFA', // Light blue
        opacity: 0.7,
    },
});

const SelectedGoalContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    margin: 30,
    marginBottom: 10,
    padding: '0 20px', // Add some padding for spacing
    textWrap: 'wrap',
    maxWidth: '100%',
});

type GoalCardProps = {
    goal: Goal;
    setSelectedGoalID: React.Dispatch<React.SetStateAction<string>>,
}

function GoalCard({ goal, setSelectedGoalID }: GoalCardProps) {
    const IconComponent = getCategoryIcon(goal.category);

    return (
        <GoalCardComponent
            onClick={() => { setSelectedGoalID(goal.id); log('prompt_generator.goal_card_click', { selectedGoal: goal.title }); }}
        >
            <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" fontSize="15px" p={2}>
                {IconComponent && <IconComponent color={goal.color} size="20px" />}
                <Typography variant="subtitle2" fontWeight="600" marginLeft={1}>
                    {goal.title}
                </Typography>
            </Box>
            <Typography variant="body2" p={2}>
                {goal.description}
            </Typography>
        </GoalCardComponent>
    )
}

type GoalCardsProps = {
    selectedPersonaID: string,
    selectedGoalID: string,
    setSelectedGoalID: React.Dispatch<React.SetStateAction<string>>,
}

function GoalCards({ selectedPersonaID, selectedGoalID, setSelectedGoalID }: GoalCardsProps) {
    const { personas, goals } = useContext(PersonasContext);
    const [availableGoals, setAvailableGoals] = useState<Goal[]>([]);

    useEffect(() => {
        const selectedPersona = personas.find((persona) => persona.id === selectedPersonaID)
        if (selectedPersona === null || selectedPersona === undefined) {
            setSelectedGoalID("");
            setAvailableGoals([]);
            return;
        }
        const allAvailableGoals = goals.filter((goal) => selectedPersona.goal_ids.includes(goal.id));
        // set goals
        setAvailableGoals(allAvailableGoals);
    }, [selectedPersonaID, setSelectedGoalID, setAvailableGoals, personas, goals]);

    if (availableGoals.length === 0) {
        return null;
    }

    // goal is already selected
    if (selectedGoalID !== "") {
        const selectedGoal = availableGoals.find((goal) => goal.id === selectedGoalID);
        if (selectedGoal == null) {
            return null;
        }
        return (
            <Box>
                <SelectedGoalContainer>
                    <Grid container spacing={1} data-testid="selected-goal">
                        <Grid item xs={12} sm={12} md={4} lg={4} key="selected-goal-card" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Box sx={{ maxWidth: '300px', position: 'relative' }}>
                                <Box sx={{ position: 'absolute', top: 0, right: 0, p: '10px' }}>
                                    <IoMdRefresh color="#9FC1D4" />
                                </Box>
                                <GoalCard
                                    goal={selectedGoal}
                                    setSelectedGoalID={() => { setSelectedGoalID(""); log('prompt_generator.goal_card_re_click'); }}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8} lg={8} key="selected-goal-description" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                            <Box display="flex" flexDirection="column">
                                <Box sx={{ m: 1, pl: 1 }} data-testid="selected-goal-header">
                                    <Typography variant="h6" color="secondary" fontWeight={700}>내 목표는 <span style={{ color: selectedGoal.color, fontWeight: 900 }}>{selectedGoal.title}</span>입니다</Typography>
                                </Box>
                                <Box display="flex" alignItems="center" textAlign="left">
                                    <FaSeedling size={'13px'} color="#9AD99F" />
                                    <Typography variant="subtitle2" p={1}>{selectedGoal.description}</Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </SelectedGoalContainer>
                <Button variant="text" color="secondary"
                    sx={{ m: 2, mt: 0, color: "gray" }} onClick={() => { setSelectedGoalID(""); log('prompt_generator.goal_card_reset_click', { selectedGoal: selectedGoal.title }); }}>
                    다른 목표 고르기
                </Button>
            </Box>
        )
    }

    // select possible goals
    return (
        <GoalCardsContainer>
            <Box data-testid="goal-header">
                <Typography variant="h5" fontWeight={600} p={2.5}>내가 이루고 싶은 목표는 무엇인가요?</Typography>
                <Typography variant="subtitle1" fontWeight={400} p={1}>내가 이루고 싶은 목표를 설정하면, 목표를 이룰 수 있는 방법들을 알려줍니다</Typography>
            </Box>
            <GoalCardsComponent>
                <Grid container spacing={1} data-testid="goals-list">
                    {availableGoals.map((goal, index) =>
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                            <GoalCard
                                goal={goal}
                                setSelectedGoalID={setSelectedGoalID} />
                        </Grid>)}
                </Grid>
            </GoalCardsComponent>
        </GoalCardsContainer>
    )
}

export default GoalCards;
