import { Box, Typography, styled } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import TextareaAutosize from '@mui/material/TextareaAutosize'; // Import TextareaAutosize
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { getRandomColors } from "../../utils/colors";
import { PersonasContext } from "../../context/PersonasContext";

const TaskCardsContainer = styled('div')({
    margin: 30,
    padding: '0 20px', // Add some padding for spacing
    textWrap: 'wrap',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
});

const TaskCardsComponent = styled('div')({
    margin: 10,
    marginTop: 20,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
});

const TaskCardComponent = styled('div')({
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    minWidth: '250px',
    justifyContent: 'center',
    alignItems: 'start',
    textAlign: 'start',
    ':hover': {
        backgroundColor: '#C2DCFA', // Light blue
        opacity: 0.7,
    },
});

const TaskTypeaheadComponent = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    m: 10,
})

type TaskCardProps = {
    task: string,
    setSelectedTask: React.Dispatch<React.SetStateAction<string>>,
    color: string,
}

function TaskCard({ task, setSelectedTask, color }: TaskCardProps) {
    return (
        <TaskCardComponent
            onClick={() => setSelectedTask(task)}
        >
            <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
                <FiberManualRecordIcon style={{ color, fontSize: 'small' }} />
                <Typography variant="subtitle2" color="#727A77" fontWeight="600" p={1}>
                    {task}
                </Typography>
            </Box>
        </TaskCardComponent >
    )
}

type TaskCardsProps = {
    selectedGoalID: string,
    selectedTask: string,
    setSelectedTask: React.Dispatch<React.SetStateAction<string>>,
}

function TaskCards({ selectedGoalID, selectedTask, setSelectedTask }: TaskCardsProps) {
    const { goals } = useContext(PersonasContext);
    const [availableTasks, setAvailableTasks] = useState<string[]>([]);
    const [randomColors,] = useState(getRandomColors())

    useEffect(() => {
        const selectedGoal = goals.find((goal) => goal.id === selectedGoalID)
        if (selectedGoal === null || selectedGoal === undefined) {
            setSelectedTask("");
            setAvailableTasks([]);
            return;
        }
        // set Tasks
        setAvailableTasks(selectedGoal.tasks);
    }, [selectedGoalID, setSelectedTask, setAvailableTasks, goals]);

    if (availableTasks.length === 0) {
        return null;
    }

    // select or input tasks
    return (
        <TaskCardsContainer>
            <Box data-testid="Task-header" sx={{ m: 2, mb: 4 }}>
                <Typography variant="h5" fontWeight={600} p={2}>내가 하려는 일이 무엇인가요?</Typography>
                <Typography variant="subtitle1" fontWeight={400} p={1}>목표를 달성하기 위해 해야 할 일을 알려주면, ChatGPT가 대신 해 줄 수 있는 프롬프트를 만들어줍니다!</Typography>
            </Box>
            <TaskTypeaheadComponent>
                <TextareaAutosize
                    aria-label="하고 싶은 일을 자세히 작성해주세요"
                    minRows={3}
                    style={{
                        flexGrow: 1,
                        maxWidth: '600px',
                        minHeight: '30px',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        color: '#377A77',
                        padding: '15px', // Add some padding for better appearance,
                        borderRadius: '10px',
                        border: '1px solid',
                        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)', // Box shadow for elevated effect
                    }}
                    placeholder="예시) 저는 작은 회사 대표입니다. AI를 활용한 비즈니스 솔루션에 관한 정보를 수집하고, 저희 회사에서 적용할 수 있을 가능성을 평가하기 위해 전문가와 상담하고 싶어요"
                    value={selectedTask}
                    onChange={(event) => setSelectedTask(event.target.value)}
                />
            </TaskTypeaheadComponent>
            <TaskCardsComponent>
                <Typography variant="body2" color="gray" fontWeight={600} p={2}>당신은 이런 일들을 할 수 있어요 (선택 가능):</Typography>
                {availableTasks.map((task, index) =>
                    <TaskCard
                        key={`task_${index}`}
                        task={task}
                        color={randomColors[index]}
                        setSelectedTask={setSelectedTask} />
                )}
            </TaskCardsComponent>
        </TaskCardsContainer>
    )
}

export default TaskCards;
