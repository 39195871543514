import { Box, Button, CircularProgress, Grid, Typography, styled } from "@mui/material";
import VectorIcon from "../others/VectorIcon";
import { FaStar } from "react-icons/fa";
import { FaNoteSticky } from "react-icons/fa6";
import { FaSeedling } from "react-icons/fa6";
import { IoMdRefresh } from "react-icons/io";
import { Persona } from "types";
import { useContext, useEffect, useState } from "react";
import { PersonasContext } from '../../context/PersonasContext'
import { getDownloadURL, log, ref, storage } from "../../config/firebase";

const PersonaContainer = styled('div')({
    flexGrow: 1,
    margin: 30,
    padding: '0 20px', // Add some padding for spacing
    textWrap: 'wrap',
    maxWidth: '100%',
});

const PersonaComponent = styled('div')({
    cursor: 'pointer', // Makes it clear that the cards are clickable
    margin: 10,
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    border: '0.5px solid',
    borderRadius: 15,
    textWrap: 'wrap',
    ':hover': {
        backgroundColor: '#C2DCFA', // Light blue background on hover,
        opacity: 0.7,
    },
    transition: 'display 0.5s, background-color 0.5s, opacity 0.5s, transform 0.5s', // Smooth transitions for background color, opacity, and transform
});

const SelectedPersonaContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    margin: 30,
    marginBottom: 10,
    padding: '0 20px', // Add some padding for spacing
    textWrap: 'wrap',
    width: '100%',
    maxWidth: '100%',
});

type CardProps = {
    persona: Persona,
    hasSelected: boolean,
    isSelected: boolean,
    setSelectedPersonaID: Function,
}
// add a loader when loading images
function PersonaCard({ persona, hasSelected, isSelected, setSelectedPersonaID }: CardProps) {
    const [src, setSrc] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const storageRef = ref(storage, persona.icon_url);
        getDownloadURL(storageRef)
            .then((url) => {
                setSrc(url);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching image URL: ", error);
                setLoading(false);
                // Handle any errors or set a default image
            });
    }, [persona.icon_url]); // Dependency array ensures this effect runs when persona.icon_url changes

    return (
        <PersonaComponent
            data-testid={`persona-card-${persona.id}`}
            sx={{
                display: hasSelected && !isSelected ? 'none' : 'flex'
            }}
            onClick={(data) => {
                setSelectedPersonaID(data);
                if (!hasSelected) {
                    log('prompt_generator.persona_card_click', { persona: persona.title })
                } else {
                    log('prompt_generator.persona_card_re_click');
                }
            }}>
            <Box justifyContent="center" alignItems="center">
                {loading ? <CircularProgress color="secondary" /> : <VectorIcon src={src} alt="icon" margin={1} width="100px" />}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography variant="subtitle1" fontWeight="600">{persona.title}</Typography>
            </Box>
            <Box sx={{ p: 1 }}>
                <Typography variant="body2">{persona.description}</Typography>
            </Box>
        </PersonaComponent >
    );
}

type PersonaCardsProps = {
    selectedPersonaID: string,
    setSelectedPersonaID: React.Dispatch<React.SetStateAction<string>>,
    setSelectedGoalID: React.Dispatch<React.SetStateAction<string>>,
}

function PersonaCards(props: PersonaCardsProps) {
    const { personas } = useContext(PersonasContext);

    // persona card is already selected
    if (props.selectedPersonaID !== "") {
        const selectedPersona = personas.find((persona) => persona.id === props.selectedPersonaID);
        if (selectedPersona == null) {
            return null;
        }
        return (
            <>
                <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                    <SelectedPersonaContainer >
                        <Grid container spacing={1} data-testid="selected-persona">
                            <Grid item xs={12} sm={12} md={4} lg={4} key="selected-persona-card" sx={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>
                                <Box sx={{ maxWidth: '300px', position: 'relative' }}>
                                    <Box sx={{ position: 'absolute', top: 0, right: 0, p: '15px' }}>
                                        <IoMdRefresh color="#9FC1D4" />
                                    </Box>
                                    <PersonaCard
                                        persona={selectedPersona}
                                        hasSelected={true}
                                        isSelected={true}
                                        setSelectedPersonaID={() => props.setSelectedPersonaID("")}
                                    />
                                </Box>
                                <Button variant="text" color="secondary" sx={{ m: 2, mt: 0, color: "gray" }} onClick={() => { props.setSelectedPersonaID(""); log('prompt_generator.persona_card_reset_click'); }}>
                                    다른 인물 고르기
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={12} md={8} lg={8} key="selected-persona-description" sx={{ display: 'flex' }}>
                                <Box display="flex" flexDirection="column" sx={{ justifyContent: 'center', alignItems: 'center', textAlign: 'left' }}>
                                    <Box sx={{ m: 1, pl: 1 }} data-testid="selected-persona-header">
                                        <Typography variant="h6" color="secondary" fontWeight={700}>나는 <span style={{ color: '#6C93B9', fontWeight: 900 }}>{selectedPersona.title}</span> 입니다</Typography>
                                    </Box>
                                    <Box display="flex" alignItems="center" textAlign="left" maxWidth="600px" pl={2}>
                                        <FaStar size={'13px'} color="#EEB72DA0" /><Typography variant="subtitle2" p={1}>{selectedPersona.objectives[0]}</Typography>
                                    </Box>
                                    <Box display="flex" alignItems="center" textAlign="left" maxWidth="600px" pl={2}>
                                        <FaSeedling size={'13px'} color="#9AD99F" /><Typography variant="subtitle2" p={1}>{selectedPersona.objectives[1]}</Typography>
                                    </Box>
                                    <Box display="flex" alignItems="center" textAlign="left" maxWidth="600px" pl={2}>
                                        <FaNoteSticky size={'13px'} color="#0A90B390" /><Typography variant="subtitle2" p={1}>{selectedPersona.objectives[2]}</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </SelectedPersonaContainer >

                </Box>
            </>
        )
    }

    // layout persona cards for selection
    return (
        <PersonaContainer>
            <Box sx={{ m: 1 }} data-testid="persona-header">
                <Typography variant="h5" fontWeight={600} p={2}>나를 한마디로 표현하자면 어떤 사람인가요?</Typography>
                <Typography variant="subtitle1" fontWeight={400} p={1}>내가 어떤 사람인지 알려주면 더 좋은 프롬프트 결과를 얻을 수 있습니다</Typography>
            </Box>
            <Box sx={{ m: 3, justifyContent: 'center' }} data-testid="persona-cards">
                <Grid container spacing={1}>
                    {personas.map((persona, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}> {/* Grid item */}
                            <PersonaCard
                                persona={persona}
                                hasSelected={props.selectedPersonaID !== ""}
                                isSelected={props.selectedPersonaID === persona.id}
                                setSelectedPersonaID={() => props.setSelectedPersonaID(persona.id)}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </PersonaContainer>
    )
}

export default PersonaCards;
