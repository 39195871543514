import React, { useEffect } from 'react';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import ReactMarkdown from 'react-markdown';
import { Box } from '@mui/system';
import { log } from '../config/firebase';

const privacyPolicy = `# Privacy Policy for Career Hacker Alex Community Website

_Last Updated: 02/05/2024_

## Introduction

Thank you for choosing to be part of our community at Career Hacker Alex Community Website ("Company", "we", "us", or "our"). We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about our policy, or our practices with regards to your personal information, please contact us at [Contact Information].

When you visit our website [Website Address], and use our services, you trust us with your personal information. We take your privacy very seriously. In this privacy notice, we describe our privacy policy. We seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy policy that you do not agree with, please discontinue use of our Sites and our services.

This privacy policy applies to all information collected through our website (such as [Website Address]), and/or any related services, sales, marketing or events (we refer to them collectively in this privacy policy as the "Services").

## 1. What Information Do We Collect?

### Personal Information You Disclose to Us

We collect personal information that you voluntarily provide to us when registering at the Services expressing an interest in obtaining information about us or our products and services, when participating in activities on the Services or otherwise contacting us.

The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make and the products and features you use. The personal information we collect can include the following:

- Name and Contact Data
- Credentials
- Payment Data
- Social Media Login Data

### Information Automatically Collected

We automatically collect certain information when you visit, use or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.

## 2. How Do We Use Your Information?

We use personal information collected via our Services for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations. We indicate the specific processing grounds we rely on next to each purpose listed below.

We use the information we collect or receive:

- To facilitate account creation and logon process
- To send you marketing and promotional communications
- Fulfill and manage your orders
- To post testimonials
- Request Feedback
- To protect our Services
- To enforce our terms, conditions and policies

## 3. Will Your Information Be Shared With Anyone?

We only share information with your consent, to comply with laws, to protect your rights, or to fulfill business obligations.

## 4. How Do We Handle Your Social Logins?

If you choose to register or log in to our services using a social media account, we may have access to certain information about you.

## 5. How Long Do We Keep Your Information?

We keep your information for as long as necessary to fulfill the purposes outlined in this privacy policy unless otherwise required by law.

## 6. How Do We Keep Your Information Safe?

We aim to protect your personal information through a system of organizational and technical security measures.

## 7. Do We Collect Information From Minors?

We do not knowingly collect data from or market to children under 18 years of age.

## 8. What Are Your Privacy Rights?

You may review, change, or terminate your account at any time.

## 9. Controls for Do-Not-Track Features

Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected.

## 10. Do California Residents Have Specific Privacy Rights?

Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.

## 11. Do We Make Updates to This Policy?

Yes, we will update this policy as necessary to stay compliant with relevant laws.

## 12. How Can You Contact Us About This Policy?

If you have questions or comments about this policy, you may email us at [Your Email] or by post to [Your Company Name], [Your Company Address].

**Contact Information**

careerhackeralex@gmail.com

Please note that this template is for educational and informational purposes only and may not be suitable for all jurisdictions or situations. Always consult with a legal professional for your specific needs.
`;

const PrivacyPolicyPage: React.FC = () => {
    useEffect(() => {
        log('privacy_policy.page_view');
    }, [])
    return (
        <Box m={5}>
            <ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]}>
                {privacyPolicy}
            </ReactMarkdown>
        </Box>
    );
};

export default PrivacyPolicyPage;
