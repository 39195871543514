import { createContext, useContext } from 'react';
import { User as FirebaseUser } from 'firebase/auth';
import { UserInfo } from 'types';

export interface AuthContextData {
    user: FirebaseUser | null;
    userInfo: UserInfo | null;
    setUserInfo: React.Dispatch<React.SetStateAction<UserInfo | null>>;
    isAuthenticated: () => boolean;
    signInWithGoogle: () => Promise<void>;
    signUpWithGoogle: () => Promise<void>;
    signInWithEmailAndPassword: (email: string, password: string) => Promise<void>;
    signUpWithEmailAndPassword: (email: string, password: string) => Promise<void>;
    logout: () => Promise<void>;
}

const AuthContext = createContext<AuthContextData | undefined>(undefined);

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

export default AuthContext;
