import React, { ReactNode, useEffect, useState, useMemo, useCallback } from 'react';
import AuthContext, { AuthContextData } from '../context/AuthContext';
import {
    signInWithPopup,
    GoogleAuthProvider,
    signInWithEmailAndPassword as firebaseSignInWithEmailAndPassword,
    onAuthStateChanged,
    User as FirebaseUser,
    signOut,
    createUserWithEmailAndPassword as firebaseCreateUserWithEmailAndPassword
} from 'firebase/auth';
import { auth } from '../config/firebase';
import { UserInfo } from 'types';

interface AuthProviderProps {
    children: ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [user, setUser] = useState<FirebaseUser | null>(null);
    const [userInfo, setUserInfo] = useState<UserInfo | null>(null);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            setUser(user);
            if (user) {
                // retrieve userInfo from the backend ('/user') of the user.uid, and set userInfo
                const response = await fetch(`${process.env.REACT_APP_API_ADDRESS}/api/user/${user.uid}`);
                const data = await response.json();
                setUserInfo(data);
            } else {
                setUserInfo(null);
            }
        });

        return () => {
            unsubscribe();
        };
    }, []);

    const isAuthenticated = () => {
        return user !== null;
    };

    const signInWithGoogle = async () => {
        const provider = new GoogleAuthProvider();
        await signInWithPopup(auth, provider);
    };

    const signUpWithGoogle = async () => {
        const provider = new GoogleAuthProvider();
        await signInWithPopup(auth, provider);
    };

    const signInWithEmailAndPassword = async (email: string, password: string) => {
        await firebaseSignInWithEmailAndPassword(auth, email, password);
    };

    const signUpWithEmailAndPassword = async (email: string, password: string) => {
        await firebaseCreateUserWithEmailAndPassword(auth, email, password);
    };

    const logout = async () => {
        await signOut(auth);
    };

    // Define the memoized functions using useCallback
    const isAuthenticatedMemoized = useCallback(isAuthenticated, [user]);
    const signInWithGoogleMemoized = useCallback(signInWithGoogle, []);
    const signUpWithGoogleMemoized = useCallback(signUpWithGoogle, []);
    const signInWithEmailAndPasswordMemoized = useCallback(signInWithEmailAndPassword, []);
    const signUpWithEmailAndPasswordMemoized = useCallback(signUpWithEmailAndPassword, []);
    const logoutMemoized = useCallback(logout, []);

    // Use useMemo to memoize the value object
    const value: AuthContextData = useMemo(
        () => ({
            user,
            userInfo,
            setUserInfo,
            isAuthenticated: isAuthenticatedMemoized,
            signInWithGoogle: signInWithGoogleMemoized,
            signUpWithGoogle: signUpWithGoogleMemoized,
            signInWithEmailAndPassword: signInWithEmailAndPasswordMemoized,
            signUpWithEmailAndPassword: signUpWithEmailAndPasswordMemoized,
            logout: logoutMemoized,
        }),
        [
            user,
            userInfo,
            setUserInfo,
            isAuthenticatedMemoized,
            signInWithGoogleMemoized,
            signUpWithGoogleMemoized,
            signInWithEmailAndPasswordMemoized,
            signUpWithEmailAndPasswordMemoized,
            logoutMemoized,
        ]
    );

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
