import { Box, Button, Divider, Typography, styled, CircularProgress } from '@mui/material';
import TopBar from '../navigations/TopBar';
import PersonaCards from '../components/promptGenerator/PersonaCards';
import { useContext, useEffect, useState } from 'react';
import GoalCards from '../components/promptGenerator/GoalCards';
import TaskCards from '../components/promptGenerator/TaskCards';
import { generatePrompt } from '../api/promptApi';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'
import rehypeRaw from "rehype-raw";
import { UserInputType } from 'types';
import { PersonasContext } from '../context/PersonasContext';
import { formatPromptUsingRegex } from '../utils/stringFormat';
import { useAuth } from '../context/AuthContext';
import { log } from '../config/firebase';
import { copyToClipboard } from '../utils/utils';
import SignInModal from '../components/profile/SignInModal';

const StyledContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'center',
    flexGrow: 1,
    width: '100%',
    maxWidth: '100%',
});

const PromptGeneratorPage: React.FC = () => {
    const { user, isAuthenticated } = useAuth();

    const { personas, goals } = useContext(PersonasContext);

    const [selectedPersonaID, setSelectedPersonaID] = useState("");
    const [selectedGoalID, setSelectedGoalID] = useState("");
    const [selectedTask, setSelectedTask] = useState("");

    const [openSignInModal, setOpenSignInModal] = useState(false);
    const [isGenerateTriggered, setGenerateTriggered] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [prompt, setPrompt] = useState('');

    const [error, setError] = useState('');

    const canGeneratePrompt = selectedPersonaID !== "" && selectedGoalID !== "" && selectedTask !== "";

    useEffect(() => {
        log('prompt_generator.page_view');
    }, [])

    useEffect(() => {
        if (!selectedPersonaID || !selectedGoalID || !selectedTask) { setGenerateTriggered(false); setPrompt(""); setIsLoading(false); };
        async function fetchData() {
            if (!selectedPersonaID || !selectedGoalID || !selectedTask) return;
            setIsLoading(true); // Ensure loading starts anew with each attempt to fetch data
            try {
                const selectedPersona = personas.find((persona) => persona.id === selectedPersonaID);
                const selectedGoal = goals.find((goal) => goal.id === selectedGoalID);
                if (selectedPersona === null || selectedPersona === undefined) {
                    throw Error('selected persona is empty');
                }
                if (selectedGoal === null || selectedGoal === undefined) {
                    throw Error('selected goal is empty');
                }
                // structure your data object here as per requirement
                const data: UserInputType = {
                    uid: user?.uid ?? "",
                    persona: selectedPersona,
                    goal: selectedGoal,
                    task: selectedTask,
                }
                await generatePrompt(JSON.stringify(data), setPrompt, setIsLoading);
            } catch (error) {
                console.error(error);
                setError('서버 오류가 생겼습니다. 다시 한 번 시도해주시거나, 디스코드 채널에 버그를 리포트해주세요!');
                setIsLoading(false);
            }
        }

        if (isGenerateTriggered) {
            fetchData();
            setGenerateTriggered(false); // Reset trigger to allow for re-triggering
        }

    }, [isGenerateTriggered, selectedPersonaID, selectedGoalID, selectedTask, personas, user?.uid, goals]);

    const handleButtonClick = () => {
        if (!user || !isAuthenticated) {
            log('prompt_generator.generate_prompt_button_click_sign_in', { persona: selectedPersonaID, goal: selectedGoalID, task: selectedTask });
            setOpenSignInModal(true);
            return;
        }
        if (canGeneratePrompt) {
            log('prompt_generator.generate_prompt_button_click', { persona: selectedPersonaID, goal: selectedGoalID, task: selectedTask })
            setGenerateTriggered(true); // Set this true to trigger the useEffect
        }
    };

    return (
        <Box
            display='flex'
            flexDirection='column'
            justifyContent='flex-start'
            alignItems='center'
            width='100%'
        >
            <TopBar />
            <StyledContainer>
                <Box mt={3}>
                    <Typography variant="h4" fontWeight={600}>프롬프트 만들기</Typography>
                </Box>
                {/* select persona */}
                <PersonaCards
                    selectedPersonaID={selectedPersonaID}
                    setSelectedPersonaID={setSelectedPersonaID}
                    setSelectedGoalID={setSelectedGoalID}
                />
                <Divider sx={{ display: selectedPersonaID ? 'block' : 'none', width: '95%' }} />
                {/* select goal */}
                <GoalCards
                    selectedPersonaID={selectedPersonaID}
                    selectedGoalID={selectedGoalID}
                    setSelectedGoalID={setSelectedGoalID}
                />
                {/* select task */}
                <Divider sx={{ display: selectedGoalID ? 'block' : 'none', width: '95%' }} />
                <TaskCards
                    selectedGoalID={selectedGoalID}
                    selectedTask={selectedTask}
                    setSelectedTask={setSelectedTask}
                />
                {
                    error ?
                        <Box display="flex" flexDirection="column">
                            <Typography color="red">
                                {error}
                            </Typography>
                            <Box m={2}>
                                <a href={"https://discord.gg/Vr7VGn9SZR"} target="_blank" rel="noopener noreferrer">
                                    <Button sx={{ margin: 1 }} variant="contained" color="error">
                                        버그 리포트하기
                                    </Button>
                                </a>
                                <Button sx={{ margin: 1 }} variant="contained" color="success" onClick={() => setError('')}>
                                    다시 시도해보기
                                </Button>
                            </Box>
                        </Box>
                        : null
                }
                {
                    canGeneratePrompt ?
                        <Box m={3}>
                            <Button
                                variant="contained"
                                color="secondary"
                                sx={{
                                    borderRadius: 6,
                                    p: 3,
                                    border: 'hidden',
                                    backgroundImage: 'linear-gradient(to bottom right, #587B7720 0%, #F7F7F0 100%)',
                                    color: 'white',
                                    minWidth: '200px',
                                    display: prompt !== "" ? 'none' : 'block',
                                }}
                                disabled={isLoading || prompt !== "" || !canGeneratePrompt}
                                onClick={handleButtonClick}>
                                <Typography variant="subtitle1" fontWeight={600}>
                                    {isLoading ? <>프롬프트 생성중입니다...<br /><CircularProgress sx={{ mt: 1 }} color="inherit" size={24} /></> : '나만의 프롬프트 만들기'}

                                </Typography>
                            </Button>
                        </Box>
                        : null
                }
                {
                    prompt !== "" ?
                        <Box m={3} p={3} maxWidth="600px" textAlign="justify" sx={{ border: '0.5px solid', borderRadius: 5, }}>
                            {
                                prompt !== "" &&
                                <Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Typography variant="h5" pb={2} fontWeight={600}>프롬프트</Typography>
                                    </Box>
                                    <ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]}>{formatPromptUsingRegex(prompt)}</ReactMarkdown>
                                </Box>
                            }
                            {
                                prompt !== "" && isLoading === false ?
                                    <Box sx={{ width: '100%', display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center', m: 1 }}>
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            sx={{ margin: 1 }}
                                            onClick={() => {
                                                log('prompt_generator.copy_prompt_button_click', { prompt })
                                                copyToClipboard(formatPromptUsingRegex(prompt));
                                            }}
                                        >
                                            <Typography variant="subtitle2">프롬프트 복사하기</Typography>
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            sx={{ margin: 1 }}
                                            onClick={() => {
                                                setSelectedTask("");
                                                setPrompt("");
                                                log('prompt_generator.create_new_prompt_button_click');
                                            }}
                                        >
                                            <Typography variant="subtitle2">새로운 프롬프트 만들기</Typography>
                                        </Button>
                                    </Box>
                                    : null
                            }
                        </Box>
                        : null
                }
                <SignInModal open={openSignInModal} setOpen={setOpenSignInModal} />
            </StyledContainer >
        </Box >
    );
}

export default PromptGeneratorPage;