// colors.ts
const pastel_colors: string[] = [
    '#79C3D680', // Light Blue
    '#79C3D6',   // Light Blue
    '#EAAAC980', // Light Pink
    '#EAAAC9',   // Light Pink
    '#9AD99F80', // Pastel Green
    '#9AD99F',   // Pastel Green
    '#EEB72DA0', // Light Beige Orange
    '#EED2B2',   // Light Greenish Gray
    '#70B8E580', // Light Sky Blue
    '#97A7E590', // Light Pastel Purple
    '#F0BD8080', // Light Orange
    '#F0BD80',   // Light Orange
    '#1CB8B190', // Light Mint
    '#F1B8B1',   // Light Coral
    '#8990B380', // Light Slate Blue
    '#0A90B390', // Light Slate Blue
    '#A3D9CF80', // Light Teal
    '#A3D9CF',   // Light Teal
    '#60954660', // Light Slate Gray
    '#C51E4660', // Salmon
    '#A9B5D980', // Light Steel Blue
    '#A9B5D9',   // Light Steel Blue
    '#F2A47780', // Light Salmon
    '#21A46C80', // Light Green
];

export const getPromptTagColor = (tag: string): string => {
    switch (tag) {
        case "마케팅": return '#A0C3D6'; // Light Blue, symbolizing communication and fluidity
        case "창의성": return '#EAAAC9'; // Soft Pink, representing imagination and innovation
        case "창업": return '#9AD99F'; // Fresh Green, indicating growth and new beginnings
        case "디지털 마케팅": return '#EEB72D'; // Bright Yellow, symbolizing energy and attention
        case "브랜딩": return '#EED2B2'; // Pale Orange, representing warmth and brand identity
        case "디자인": return '#70B8E5'; // Bright Blue, indicating creativity and design thinking
        case "자기계발": return '#F4CAE0'; // Soft Pink, representing personal growth
        case "리더십": return '#B7C0EE'; // Light Purple, indicating wisdom and respect
        case "콘텐츠 크리에이션": return '#FAD02E'; // Pale Yellow, symbolizing creativity and energy
        case "데이터 분석": return '#C2E1F5'; // Sky Blue, representing clarity and precision
        case "인공지능": return '#D5E8D4'; // Soft Green, hinting at innovation and technology
        case "유튜브": return '#FABFD2'; // Light Coral, connecting to digital media
        case "전략 계획": return '#A7D8B8'; // Mint Green, symbolizing planning and strategy
        case "혁신": return '#E3C567'; // Soft Gold, indicating breakthrough and creativity
        case "전문성 강화": return '#B9D7ED'; // Soft Blue, representing skill development
        case "언어 학습": return '#FDE2C8'; // Pale Orange, suggesting communication and learning
        case "교육": return '#B9E2F4'; // Light Sky Blue, related to learning and knowledge
        case "스토리텔링": return '#E6C6F7'; // Lavender, symbolizing creativity and narrative
        case "전략": return '#F9D5E5'; // Pale Pink, indicating thoughtfulness and planning
        case "사고력 증진": return '#B9E3C6'; // Soft Mint, representing mental growth and clarity
        case "건강": return '#F4BFBF'; // Soft Red, connecting to health and vitality
        case "개발자": return '#B7D3F2'; // Light Blue, related to technology and coding
        case "은퇴자": return '#D9BFD9'; // Pale Purple, representing leisure and the later stages of life
        case "소통": return '#CDE0C9'; // Soft Green, symbolizing communication and openness
        case "글로벌 커뮤니케이션": return '#FAD0C9'; // Pale Salmon, indicating worldwide interaction
        case "개발": return '#ACE7EF'; // Cyan, related to creation and technology
        case "운동": return '#F9E0B7'; // Soft Orange, connected to energy and physical activity
        case "AI": return '#CBD5E1'; // Light Grey-Blue, symbolizing futuristic technology
        case "콘텐츠 제작": return '#D3EBCD'; // Light Green, indicating creativity and production
        case "지식 향상": return '#F3D1F4'; // Pale Violet, related to knowledge and intellect
    }
    // if not any of the above, return random color 
    return getRandomColor();
}

export const getRandomColor = (): string => {
    const randomIndex = Math.floor(Math.random() * pastel_colors.length);
    return pastel_colors[randomIndex];
};

export const getRandomColors = (): string[] => {
    const colors = [...pastel_colors]; // Create a copy of the pastel_colors array to shuffle
    for (let i = colors.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [colors[i], colors[j]] = [colors[j], colors[i]]; // Swap
    }
    return colors;
};