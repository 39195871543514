import React, { useEffect } from 'react';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import ReactMarkdown from 'react-markdown';
import { Box } from '@mui/system';
import { log } from '../config/firebase';

const terms = `# Terms of Service for Career Hacker Alex Community Website

_Last Updated: 02/05/2024_

Welcome to Career Hacker Alex Community Website! By accessing or using our website, services, or applications (collectively, the "Service"), you agree to be bound by these Terms of Service ("Terms"). If you do not agree to these Terms, please do not use the Service.

## 1. Acceptance of Terms

The Service is offered subject to acceptance without modification of all of the terms and conditions contained herein and all other operating rules, policies, and procedures that may be published from time to time on this Site by us.

## 2. Modification of Terms

We reserve the right, at our sole discretion, to modify or replace any of these Terms, or change, suspend, or discontinue the Service (including without limitation, the availability of any feature, database, or content) at any time by posting a notice on the Site or by sending you notice through the Service, via email or by another appropriate means of electronic communication. We may also impose limits on certain features and services or restrict your access to parts or all of the Service without notice or liability.

## 3. User Conduct

You are responsible for all your activity in connection with the Service. You shall not, and shall not permit any third party to, either (a) take any action or (b) upload, download, post, submit or otherwise distribute or facilitate distribution of any content on or through the Service, including without limitation any User Content, that:

- infringes any patent, trademark, trade secret, copyright, right of publicity, or other rights of any person or entity;
- is unlawful, threatening, abusive, harassing, defamatory, libelous, deceptive, fraudulent, invasive of another's privacy, tortious, obscene, offensive, or profane;
- constitutes unauthorized or unsolicited advertising, junk or bulk email ("spamming");
- involves commercial activities and/or sales without our prior written consent, such as contests, sweepstakes, barter, advertising, or pyramid schemes.

## 4. User Content

You own all content and information you post or share on the Service ("User Content"), but you also grant us a non-exclusive, transferable, sublicensable, royalty-free, worldwide license to use any User Content that you post on or in connection with the Service.

## 5. Registration

You may be required to register with the Service. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.

## 6. Privacy

Our Privacy Policy, which also governs your use of the Service, explains how we collect, use, and disclose information about our users. By using the Service, you agree to our collection, use, and disclosure of your personal information in accordance with our Privacy Policy.

## 7. Termination

We may terminate your access to all or any part of the Service at any time, with or without cause, with or without notice, effective immediately.

## 8. Disclaimers

The Service is provided "as is" and "as available" and without warranty of any kind, express or implied.

## 9. Limitation of Liability

In no event shall we, nor our directors, employees, agents, partners, suppliers, or content providers, be liable under contract, tort, strict liability, negligence, or any other legal or equitable theory with respect to the service.

## 10. Governing Law

These Terms shall be governed by and construed in accordance with the laws of [Your Country/State], without regard to its conflict of laws rules.

## 11. Arbitration

Any dispute arising from or relating to the subject matter of these Terms shall be finally settled by arbitration in [Your Country/State].

## 12. Entire Agreement

These Terms, including any terms and conditions incorporated herein, constitute the entire agreement between you and us regarding the use of the Service.

## 13. Contact Us

If you have any questions about these Terms, please contact us at [Insert Contact Information].

`;

const TermsAndConditionsPage: React.FC = () => {
    useEffect(() => {
        log('terms_and_conditions.page_view');
    }, [])
    return (
        <Box m={5}>
            <ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]}>
                {terms}
            </ReactMarkdown>
        </Box>
    );
};

export default TermsAndConditionsPage;
