import React from "react";
import {
    Dialog,
} from "@mui/material";
import SignInComponent from "./SignInComponent";

interface SignInModalProps {
    open: boolean,
    setOpen: (open: boolean) => void,
}

const SignInModal: React.FC<SignInModalProps> = ({ open, setOpen }) => {

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <SignInComponent callback={() => handleClose()} />
        </Dialog>
    );
};

export default SignInModal;
