import React, { useCallback, useEffect, useState } from 'react';
import { Box, Card, CardContent, Typography, CircularProgress, styled, Chip, Grid, Button, Dialog, DialogContent, TextField, Divider } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import TopBar from '../navigations/TopBar';
import { Prompt } from 'types';
import { getPromptTagColor } from '../utils/colors';
import { formatDistanceToNow } from 'date-fns';
import { ko } from 'date-fns/locale';
import { useAuth } from '../context/AuthContext';
import SignInModal from '../components/profile/SignInModal';
import { log } from '../config/firebase';
import PromptCard from '../components/promptExplorer/PromptCard';

const MOST_COMMON_TAGS = ["마케팅", "커리어", "창의성", "창업", "자기계발", "디지털 마케팅", "리더십", "디자인", "콘텐츠 제작", "데이터 분석", "유튜브", "개발자"];

const StyledContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    flexGrow: 1,
    maxWidth: '100%',
    padding: '20px',
    margin: 20,
});

const StyledSearchBar = styled(Box)({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "end",
    width: "80%",
    maxWidth: "600px",
    margin: '30px',
    marginBottom: '0px',
    padding: "0px 30px 10px 30px",
    borderRadius: "50px",
    boxShadow: "1px 1px 5px 1px rgba(0, 0, 0, 0.2)",
    overflow: "auto",
});

const StyledCard = styled(Card)({
    maxWidth: 350,
    maxHeight: 400,
    margin: '10px',
    borderRadius: '20px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
    transition: 'transform 0.2s',
    '&:hover': {
        transform: 'scale(1.05)',
        backgroundColor: 'rgba(0,0,0,0.05)',
    },
});

const StyledChip = styled(Chip)({
    margin: '2px', // Add margin as needed
    '& .MuiChip-label': {
        fontSize: '10px', // Adjust the font size as needed
        color: 'primary',
    },
    '&:hover': {
        transform: 'scale(1.05)',
    },
});

const StyledDialog = styled(Dialog)({
    '& .MuiDialog-paper': {
        borderRadius: '20px', // Set the border radius here
    },
});

// Function to truncate text if it's too long
const truncateText = (text: string, maxLength: number) => {
    if (text.length <= maxLength) {
        return text;
    }
    return text.slice(0, maxLength) + '...';
};

const AllPromptsPage: React.FC = () => {
    const { user, isAuthenticated } = useAuth();
    const [prompts, setPrompts] = useState<Prompt[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const [selectedPrompt, setSelectedPrompt] = useState<Prompt | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [openSignInModal, setOpenSignInModal] = useState(false);

    // search & filter
    const [searchQueryString, setSearchQueryString] = useState<string>('');
    const [searchQueries, setSearchQueries] = useState<string[]>([]);

    const fetchPrompts = useCallback(async (lastPromptId: string | null) => {
        setIsLoading(true);
        try {
            let queryParams = new URLSearchParams();
            if (lastPromptId !== null) {
                queryParams.append('lastPromptId', lastPromptId);
            }
            searchQueries.forEach(query => queryParams.append('search', query));

            const response = await fetch(`${process.env.REACT_APP_API_ADDRESS}/api/prompts?${queryParams.toString()}`);
            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

            const data = await response.json() as Prompt[];
            if (lastPromptId === null) {
                setPrompts(data);
            } else {
                setPrompts(prev => [...prev, ...data]);
            }
        } catch (error) {
            console.error('Error fetching prompts:', error);
        } finally {
            setIsLoading(false);
        }
    }, [searchQueries]);

    useEffect(() => {
        log('prompt_explorer.page_view');
        fetchPrompts(null); // Fetch prompts on initial load
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        fetchPrompts(null); // Refetch prompts when search queries change
    }, [searchQueries, fetchPrompts]);

    const handleLoadMore = () => {
        fetchPrompts(prompts[prompts.length - 1]?.id ?? null);
        log('prompt_explorer.load_more_prompts_button_click')
    };

    const handleCardClick = (prompt: Prompt) => {
        // if user is not logged in, ask for log in
        if (!user || !isAuthenticated) {
            log('prompt_explorer.prompt_card_open_sign_in', { prompt: prompt.id });
            setOpenSignInModal(true);
            return;
        }
        setSelectedPrompt(prompt);
        setIsModalOpen(true);
        log('prompt_explorer.prompt_card_open', { prompt: prompt.id });
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedPrompt(null);
        log('prompt_explorer.prompt_card_close');
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault(); // Prevent the default behavior
            if (searchQueryString.trim()) {
                setSearchQueries(prev => [...prev, searchQueryString.trim()]);
                setSearchQueryString('');
                log('prompt_explorer.add_new_prompt_filter_tag', { tag: searchQueryString.trim() });
            }
        }
    };

    const handleDeleteQuery = (queryToDelete: string) => {
        setSearchQueries(prev => prev.filter(query => query !== queryToDelete));
    };

    const handleClickAddTag = (tag: string) => {
        setSearchQueries(prev => [...prev, tag]);
        log('prompt_explorer.prompt_filter_tag_click', { tag });
    }

    return (
        <Box
            display='flex'
            flexDirection='column'
            justifyContent='flex-start'
            alignItems='center'
            width='100%'
        >
            <TopBar />
            {/* search bar */}
            <StyledSearchBar>
                {searchQueries.map((query, index) => (
                    <Chip
                        key={`query_${index}`}
                        label={query}
                        onDelete={() => handleDeleteQuery(query)}
                        sx={{ m: 0.5 }}
                    />
                ))}
                <TextField
                    label="찾으시는 프롬프트 키워드를 입력해주세요"
                    variant="standard"
                    fullWidth
                    value={searchQueryString}
                    onChange={(e) => setSearchQueryString(e.target.value)}
                    onKeyPress={handleKeyPress}
                />
                <Divider orientation='vertical' sx={{ height: '30px', width: '10px', m: '0 10px' }} />
                <SearchIcon />
            </StyledSearchBar>
            {/* tags */}
            <Box display="flex" flexWrap="wrap" m={2} mb={0} justifyContent="center" alignItems="center">
                {MOST_COMMON_TAGS.map((tag, index) => (
                    <StyledChip
                        key={index}
                        label={tag}
                        color="primary"
                        onClick={() => handleClickAddTag(tag)}
                        style={{
                            backgroundColor: getPromptTagColor(tag),
                            color: 'black',
                            zIndex: 5,
                            boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2)',
                            margin: 5,
                        }}
                    />
                ))}
            </Box>
            {/* grid prompt cards */}
            <StyledContainer>
                {
                    prompts.map((prompt, index) => (
                        <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                            <StyledCard>
                                <CardContent >
                                    {/* header */}
                                    <Box onClick={() => handleCardClick(prompt)}>
                                        <Box m={1}>
                                            <Typography variant="subtitle2">{prompt.gpt_persona}의</Typography>
                                            <Typography variant="subtitle1" fontWeight={600} >{prompt.prompt_title}</Typography>
                                            <Typography variant="caption" sx={{ textAlign: 'end' }}>
                                                {formatDistanceToNow(prompt.updatedAt ? new Date(prompt.updatedAt) : new Date(), { addSuffix: true, locale: ko })}
                                            </Typography>
                                        </Box>
                                        {/* prompt */}
                                        <Typography variant="body2" m={1} mt={1} mb={2}>
                                            {truncateText(prompt.prompt, 100)} {/* Adjust the maxLength as needed */}
                                        </Typography>
                                    </Box>
                                    {/* tags chips */}
                                    <Box>
                                        {prompt.prompt_tags?.map((tag, tagIndex) => (
                                            <StyledChip
                                                key={tagIndex}
                                                label={tag}
                                                color="primary"
                                                onClick={() => handleClickAddTag(tag)}
                                                style={{
                                                    backgroundColor: getPromptTagColor(tag),
                                                    color: 'black',
                                                    zIndex: 5,
                                                }}
                                            />
                                        ))}
                                    </Box>
                                    {/* TODO: add user feedback: like & comments counts */}
                                </CardContent>
                            </StyledCard>
                        </Grid>
                    ))
                }
                {isLoading ?
                    <CircularProgress color="success" /> : null}
                {!isLoading && prompts.length > 0 ?
                    <Button variant="text" color="secondary" onClick={handleLoadMore} disabled={isLoading} sx={{ m: 3, p: 2, borderRadius: 5 }}>
                        <Typography variant="h6" >
                            더 보기
                        </Typography>
                    </Button> : null
                }
                {/* Card Content Modal */}
                <StyledDialog open={isModalOpen} onClose={handleCloseModal} maxWidth="sm">
                    <DialogContent >
                        {selectedPrompt && (
                            <PromptCard prompt={selectedPrompt} />
                        )}
                    </DialogContent>
                </StyledDialog>
                <SignInModal open={openSignInModal} setOpen={setOpenSignInModal} />
            </StyledContainer>
        </Box>
    );
}

export default AllPromptsPage;
