import { Box, Button, Card, Dialog, DialogContent, Divider, TextField, Typography, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import { Goal, Persona } from 'types';
import { useAuth } from '../context/AuthContext';
import TopBar from '../navigations/TopBar';
import { getDownloadURL, log, ref, storage } from '../config/firebase';

const StyledContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    margin: 20,
    justifyContent: 'center',
    alignItems: 'center',
});

const StyledDialog = styled(Dialog)({
    '& .MuiDialog-paper': {
        borderRadius: '20px', // Set the border radius here
    },
});

interface PersonaCardProps {
    persona: Persona;
    onClick: any;
}

const PersonaCard = ({ persona, onClick }: PersonaCardProps) => {
    const [src, setSrc] = useState('');
    useEffect(() => {
        const storageRef = ref(storage, persona.icon_url);
        getDownloadURL(storageRef)
            .then((url) => {
                setSrc(url);
            })
            .catch((error) => {
                console.error("Error fetching image URL: ", error);
                // Handle any errors or set a default image
            });
    }, [persona.icon_url]); // Dependency array ensures this effect runs when persona.icon_url changes
    return (
        <Card sx={{ maxWidth: '300px', maxHeight: '300px', m: 2, p: 2, display: 'flex', flexDirection: 'column', overflow: "scroll" }}>
            <Box display="flex" flexDirection="row" width="100%" alignItems="center" justifyContent="center">
                <img src={src} alt={persona.title} width="56px" />
                <Typography variant="subtitle1" ml={2} fontWeight={600}>{persona.title}</Typography>
            </Box>
            <Box display="flex" justifyContent="center" width="100%" mb={1}>
                <Button variant="text" onClick={onClick}>수정하기</Button>
            </Box>
            <Divider />
            {Object.entries(persona).map(([key, value], i) => (
                <Box key={i} m={1}>
                    <Typography variant="subtitle1" fontWeight={600}>{key}</Typography>
                    <Typography variant="subtitle2">{String(value)}</Typography>
                </Box>
            ))}
        </Card>
    )
}

const GoalCard = (goal: Goal) => {
    return (
        <Card sx={{ maxWidth: '300px', maxHeight: '300px', m: 2, p: 2, display: 'flex', flexDirection: 'column', overflow: "scroll" }}>
            <Box display="flex" flexDirection="row" width="100%" alignItems="center" justifyContent="center">
                <img src={goal.icon_url} alt={""} width="56px" />
                <Typography variant="subtitle1" ml={2} fontWeight={600}>{goal.title}</Typography>
            </Box>
            <Box display="flex" justifyContent="center" width="100%" mb={1}>
                <Button variant="text" onClick={() => { alert('이것도 곧 만들어볼게요 ^^;') }}>수정하기</Button>
            </Box>
            <Divider />
            <img src={goal.icon_url} alt={goal.title} width="56px" />
            {Object.entries(goal).map(([key, value], i) => (
                <Box key={i} m={1}>
                    <Typography variant="subtitle1" fontWeight={600}>{key}</Typography>
                    <Typography variant="subtitle2">{String(value)}</Typography>
                </Box>
            ))}
        </Card>
    )
}

const AdminPage: React.FC = () => {
    const { user, userInfo, isAuthenticated } = useAuth();
    const isNotAllowd = user === null || !isAuthenticated || userInfo?.role !== "ADMIN";
    const [personaDataArray, setPersonaDataArray] = useState<Persona[]>([]);
    const [goalDataArray, setGoalDataArray] = useState<Goal[]>([]);

    const [_, setSelectedPersonaID] = useState<string | null>(null);
    const [tempSelectedPersona, setTempSelectedPersona] = useState<Persona | null>(null);
    const [personaModalOpen, setPersonaModalOpen] = useState(false);
    const [updatingPersona, isUpdatingPersona] = useState(false);

    // const [selectedGoal, setSelectedGoal] = useState<Goal | null>(null);

    useEffect(() => {
        log('admin.page_view');
    }, [])

    useEffect(() => {
        if (isNotAllowd) return;
        // fetch persona data from "/api/personas"
        fetch(`${process.env.REACT_APP_API_ADDRESS}/api/personas`)
            .then(response => response.json())
            .then(data => setPersonaDataArray(data));

        // fetch goal data from "/api/goals"
        fetch(`${process.env.REACT_APP_API_ADDRESS}/api/goals`)
            .then(response => response.json())
            .then(data => setGoalDataArray(data));
    }, [isNotAllowd]);

    if (isNotAllowd) {
        return (
            <>
                <Box p={3}><h3>페이지를 찾을 수 없습니다</h3></Box>
            </>
        );
    }

    const uploadPersonas = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_ADDRESS}/api/uploadMockPersonas`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('HTTP error ' + response.status);
            }
        } catch (error) {
            console.error('Error uploading personas:', error);
        }
    };

    const uploadGoals = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_ADDRESS}/api/uploadMockGoals`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('HTTP error ' + response.status);
            }
        } catch (error) {
            console.error('Error uploading goals:', error);
        }
    };

    const handleInputPersonaChange = (e: React.ChangeEvent<HTMLInputElement>, key: keyof Persona) => {
        const value = e.target.value; // This is always a string for input values
        setTempSelectedPersona(current => {
            if (current === null) return null;
            // Return a new object with the updated key-value pair
            // Ensuring that we're not introducing 'undefined' values
            return { ...current, [key]: value };
        });
    };

    const updatedSelectedPersona = async (persona: Persona) => {
        // Update the persona in the database "/api/personas"
        isUpdatingPersona(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_ADDRESS}/api/personas/${persona.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(persona),
            });
            if (!response.ok) {
                throw new Error('HTTP error ' + response.status);
            }
            setSelectedPersonaID(null);
            setTempSelectedPersona(null);
            setPersonaModalOpen(false);
        } catch (error) {
            console.error('Error updating persona:', error);
        }
        isUpdatingPersona(false);
    }

    return (
        <>
            <TopBar />
            <StyledContainer>
                <Typography variant="h3" p={2} mb={2}>관리자 페이지</Typography>
                <Divider sx={{ width: "100%" }} />
                <Box display="flex" flexDirection="column" m={3} justifyContent="center" alignItems="center" overflow="scroll">
                    <Typography variant="h4" p={2}>페르소나 데이터 관리하기</Typography>
                    <Box display="flex" flexDirection="row" flexWrap="wrap">
                        {personaDataArray.map((persona, index) => (
                            <Box key={index}>
                                <PersonaCard persona={persona} onClick={() => { setSelectedPersonaID(persona.id); setTempSelectedPersona(Object.assign({}, persona)); setPersonaModalOpen(true); }} />
                            </Box>
                        ))}
                    </Box>
                </Box>
                <Box m={3}>
                    <Button variant="outlined" onClick={() => alert('곧 만들어둘게요!')} sx={{ marginRight: 2 }}>모든 페르소나 데이터 백업하기</Button>
                    <Button variant="contained" onClick={uploadPersonas}>모든 페르소나 데이터 리셋하기</Button>
                </Box>
                {/* Add Selected Persona Modal */}

                <Divider sx={{ width: "100%" }} />
                <Box display="flex" flexDirection="column" m={5} justifyContent="center" alignItems="center" overflow="scroll">
                    <Typography variant="h4">목표 데이터 관리</Typography>
                    <Box display="flex" flexDirection="row" flexWrap="wrap">
                        {goalDataArray.map((goal, index) => (
                            <Box key={index}>
                                <GoalCard {...goal} />
                            </Box>
                        ))}
                    </Box>
                </Box>
                <Box m={3}>
                    <Button variant="outlined" onClick={() => alert('곧 만들어둘게요!')} sx={{ marginRight: 2 }}>모든 목표 데이터 백업하기</Button>
                    <Button variant="contained" onClick={uploadGoals}>모든 목표 데이터 리셋하기</Button>
                </Box>
                <Divider sx={{ width: "100%" }} />

                {/* Persona Edit Dialog */}
                <StyledDialog open={personaModalOpen} onClose={() => setPersonaModalOpen(!personaModalOpen)} maxWidth="sm">
                    <DialogContent >
                        {tempSelectedPersona && (
                            <Box m={1}>
                                {/* Create Persona Field Editor */}
                                {tempSelectedPersona && (
                                    <Box m={1}>
                                        <Box display="flex" flexDirection="row" maxWidth="100%" alignItems="center" justifyContent="center">
                                            <Typography variant="subtitle1" ml={2} fontWeight={600}>{tempSelectedPersona.title}</Typography>
                                        </Box>
                                        {Object.entries(tempSelectedPersona).map(([key, value], i) => (
                                            <Box key={i} m={1}>
                                                <Typography variant="subtitle1" fontWeight={600}>{key}</Typography>
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    value={value.toString()}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputPersonaChange(e, key as keyof Persona)}
                                                    margin="dense"
                                                />
                                            </Box>
                                        ))}
                                    </Box>
                                )}

                                {/* Submit Button */}
                                <Box display="flex" justifyContent="center" alignItems="center">
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        sx={{ margin: 1 }}
                                        disabled={updatingPersona}
                                        onClick={() => updatedSelectedPersona(tempSelectedPersona)}
                                    >
                                        <Typography variant="subtitle2">수정하기</Typography>
                                    </Button>
                                </Box>
                            </Box>
                        )}
                    </DialogContent>
                </StyledDialog>
            </StyledContainer>
        </>
    );
};

export default AdminPage;