import React, { createContext, useEffect, useState } from 'react';
import { Goal, Persona } from 'types';

interface PersonasContextProps {
    personas: Persona[];
    goals: Goal[];
}

export const PersonasContext = createContext<PersonasContextProps>({
    personas: [],
    goals: [],
});

interface PersonasProviderProps {
    children: React.ReactNode;
}

export const PersonasProvider: React.FC<PersonasProviderProps> = ({ children }) => {
    const [personas, setPersonas] = useState<Persona[]>([]);
    const [goals, setGoals] = useState<Goal[]>([]);

    useEffect(() => {
        const fetchPersonas = fetch(`${process.env.REACT_APP_API_ADDRESS}/api/personas`).then(response => response.json());
        const fetchGoals = fetch(`${process.env.REACT_APP_API_ADDRESS}/api/goals`).then(response => response.json());

        Promise.all([fetchPersonas, fetchGoals])
            .then(([personasData, goalsData]) => {
                setPersonas(personasData);
                setGoals(goalsData);
            })
            .catch(error => {
                console.error("Error fetching data:", error);
                // Here, you could also set error states if you have them defined
            });

    }, []); // The empty array ensures this effect runs only once after the initial render

    return (
        <PersonasContext.Provider value={{ personas, goals }}>
            {children}
        </PersonasContext.Provider>
    );
};
