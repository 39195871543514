import React, { useState } from 'react';
import { Box, Typography, styled, Button, TextField, CircularProgress } from '@mui/material';
import { useAuth } from '../../context/AuthContext';

import { UserInfo } from 'types';
import { log } from '../../config/firebase';

const StyledContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    margin: 20,
    justifyContent: 'center',
    alignItems: 'center',
});
const StyledProfileInfo = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: "flex-start",
    alignItems: 'flex-start',
    padding: '20px',
    textAlign: 'left',
    width: '100%',
    maxWidth: '400px',
});

const StyledUserInputHeader = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '30px',
});

interface UserInfoCardProps {
}

const UserInfoCard: React.FC<UserInfoCardProps> = () => {
    const { user, userInfo, setUserInfo, isAuthenticated } = useAuth();
    const [username, setUsername] = useState(userInfo?.username || '');
    const [bio, setBio] = useState(userInfo?.bio || '');
    const [isLoading, setIsLoading] = useState(false);

    // if userInfo is not null, return null
    if (user && isAuthenticated() && userInfo && userInfo?.username !== '' && userInfo?.bio !== '') {
        return null;
    }

    const updateUserInformation = async (userInfo: UserInfo) => {
        setIsLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_ADDRESS}/api/user`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userInfo),
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const userInfoData = await response.json()
            setUserInfo(userInfoData as UserInfo);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
        setIsLoading(false);
    };

    const handleUpdate = async () => {
        if (user && user.uid) {
            const updatedUserInfo = {
                uid: user.uid,
                username,
                bio
            } as UserInfo;
            await updateUserInformation(updatedUserInfo);
            log('profile.update_user_info', { username, bio })
        }
    };

    return (
        <StyledContainer>
            <StyledUserInputHeader>
                <Typography variant="h5">
                    커리어해커님, 환영합니다!
                </Typography>
                <Typography variant="subtitle1" mt={2}>
                    더 쉽고 빠르게 정보를 전달 드릴 수 있도록 다음을 작성해주세요.
                </Typography>
            </StyledUserInputHeader>
            <StyledProfileInfo>
                <Box display="flex" flexDirection="column" justifyContent="space-between" width="100%">
                    <Typography variant="subtitle1" fontWeight={600} mb={1}>
                        1. 이 플랫폼에서 사용하고 싶은 이름이 무엇인가요?
                    </Typography>
                    <TextField
                        label="사용하고 싶은 이름을 알려주세요"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </Box>
                <Box display="flex" flexDirection="column" justifyContent="space-between" width="100%">
                    <Typography variant="subtitle1" fontWeight={600} mt={2} mb={1}>
                        2. 원하시는 정보를 더 빠르고 쉽게 전달드리기 위해 여러분에 대해 알아야 할 것은 무엇입니까? <br />
                        <span style={{ fontWeight: 500 }}>(챗GPT 커스텀 인스트럭션 1번 질문)</span>
                    </Typography>
                    <TextField
                        label="여러분에 대한 정보를 입력해주세요"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        multiline
                        rows={4}
                        value={bio}
                        onChange={(e) => setBio(e.target.value)}
                    />
                </Box>
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={username === '' || bio === '' || isLoading}
                    onClick={handleUpdate}
                    sx={{ mt: 2 }}
                >
                    <Typography>
                        커리어해킹 시작하기<br />
                        {isLoading ? <CircularProgress sx={{ mt: 1 }} color="inherit" size={24} /> : null}
                    </Typography>
                </Button>
            </StyledProfileInfo>
        </StyledContainer>
    );
};

export default UserInfoCard;
