import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import ROUTES from '../utils/routes';
import TopBar from '../navigations/TopBar';
import React, { useEffect, useState } from 'react';
import { log } from '../config/firebase';

const HIGHLIGHT_COLOR = "#E4D7C9";

const StyledContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    textAlign: 'center',
    flexGrow: 1,
    backgroundImage: `url('/img/background.png')`, // Set the path to your background image
    backgroundSize: 'cover', // Adjust as needed
    maxWidth: '100%',
    overflow: 'hidden',
    color: 'white',
});

const StyledSection = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    flexGrow: 1,
});

const Main: React.FC = () => {
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    useEffect(() => {
        log('landing_page_view');
        setIsSmallScreen(window.innerWidth < 600);
    }, []);

    return (
        <StyledContainer>
            {/* Prompt Website */}
            <StyledSection sx={{
                alignItems: 'start',
                justifyContent: 'flex-start',
                textAlign: 'left',
                backgroundImage: `url('/img/background3.png')`, // Set the path to your background image
                backgroundSize: 'cover', // Adjust as needed
                backgroundPosition: 'top', // Adjust as needed
                backgroundRepeat: 'no-repeat',
                flexGrow: 1,
                minHeight: '100vh',
            }}>
                <TopBar color={HIGHLIGHT_COLOR} />
                <Box flexGrow={1} display="flex" flexDirection="column" justifyContent="center" height="100%" alignItems="start" width="100%">
                    <Box m="10%">
                        {/* title & description */}
                        <Typography variant={isSmallScreen ? "h5" : "h3"} sx={{ fontFamily: 'Black Han Sans', color: HIGHLIGHT_COLOR }}>
                            인공지능으로
                        </Typography>
                        <Typography variant={isSmallScreen ? "h4" : "h2"} sx={{ fontFamily: 'Black Han Sans' }}>
                            커리어를 해킹하다
                        </Typography>
                        <Typography variant={isSmallScreen ? "caption" : "h6"}>
                            실리콘밸리 엔지니어가 소개하는 인공지능 커리어해킹<br />
                            모두가 함께 만들어가는 챗GPT 프롬프트 커뮤니티<br />
                            지금 바로 경험해보세요!<br />
                        </Typography>
                        {/* nav buttons */}
                        <Box mt={3} flexDirection="row" justifyContent="center" alignItems="center" flexGrow={1}>
                            <Link to={ROUTES.prompt_generator.path} style={{ marginRight: 10 }}>
                                <Button color="info" variant="contained" onClick={() => log('main.prompt_generator_link_click')}>
                                    <Typography variant={isSmallScreen ? "caption" : "subtitle2"} fontWeight={600}>
                                        프롬프트 만들기
                                    </Typography>
                                </Button>
                            </Link>
                            <Link to={ROUTES.prompt_explorer.path}>
                                <Button color="success" variant="contained" onClick={() => log('main.prompt_explorer_link_click')}>
                                    <Typography variant={isSmallScreen ? "caption" : "subtitle2"} fontWeight={600}>
                                        프롬프트 찾아보기
                                    </Typography>
                                </Button>
                            </Link>
                        </Box>
                    </Box>
                </Box>
            </StyledSection>

            {/* Online Lecture */}
            <StyledSection sx={{
                alignItems: 'end',
                justifyContent: 'flex-end',
                textAlign: 'right',
                backgroundImage: `url('/img/background2.png')`, // Set the path to your background image
                backgroundSize: 'cover', // Adjust as needed
                backgroundPosition: '15% 50%', // Adjust as needed
                minHeight: '80vh',
                flexGrow: 1,
            }}>
                <Box m="10%">
                    {/* title & description */}
                    <Typography variant={isSmallScreen ? "h4" : "h2"} sx={{ fontFamily: 'Black Han Sans' }}>
                        실리콘밸리 1% 리더의 <br />
                        ChatGPT 강의
                    </Typography>
                    <Typography variant={isSmallScreen ? "caption" : "h6"}>
                        2500만 모든 직장인들을 위한 <br />
                        ChatGPT로 커리어해킹 하는 방법!<br />
                        기초 지식부터 챗봇 생성까지 모두 알려드립니다.<br />
                    </Typography>
                    {/* nav buttons */}
                    <Box mt={3} flexDirection="row" justifyContent="center" alignItems="center" flexGrow={1}>
                        <a href={"https://bit.ly/3U3iulH"} target="_blank" rel="noopener noreferrer">
                            <Button color="success" variant="contained" onClick={() => log('main.online_lecture_link_click')}>
                                <Typography variant={isSmallScreen ? "caption" : "subtitle2"} fontWeight={600}>
                                    ChatGPT 강의 보기
                                </Typography>
                            </Button>
                        </a>
                    </Box>
                </Box>
            </StyledSection>

            {/* Discord Community */}
            <StyledSection sx={{
                alignItems: 'start',
                justifyContent: 'flex-start',
                textAlign: 'left',
                backgroundImage: `url('/img/background1.png')`, // Set the path to your background image
                backgroundSize: 'cover', // Adjust as needed
                backgroundPosition: '15% 50%', // Adjust as needed
                flexGrow: 1,
                minHeight: '80vh',
            }}>
                <Box m="10%">
                    {/* title & description */}
                    <Typography variant={isSmallScreen ? "h4" : "h2"} sx={{ fontFamily: 'Black Han Sans' }}>
                        커리어해커 <br /> 디스코드 커뮤니티
                    </Typography>
                    <Typography variant={isSmallScreen ? "caption" : "h6"}>
                        커리어와 인공지능에 관한 모든 정보와 <br />
                        국내 최고의 전문가들을 만나보세요 <br />
                    </Typography>
                    {/* nav buttons */}
                    <Box mt={3} flexDirection="row" justifyContent="center" alignItems="center" flexGrow={1}>
                        <a href={"https://discord.gg/Kp3SUkttKt"} target="_blank" rel="noopener noreferrer">
                            <Button color="info" variant="contained" onClick={() => log('main.discord_link_click')}>
                                <Typography variant={isSmallScreen ? "caption" : "subtitle2"} fontWeight={600}>
                                    디스코드 채널 보기
                                </Typography>
                            </Button>
                        </a>
                    </Box>
                </Box>
            </StyledSection>

            {/* YouTube Channel */}
            <StyledSection sx={{
                alignItems: 'end',
                justifyContent: 'flex-end',
                textAlign: 'right',
                backgroundImage: `url('/img/background10.png')`, // Set the path to your background image
                backgroundSize: 'cover', // Adjust as needed
                backgroundPosition: '100% 90%', // Adjust as needed
                minHeight: '80vh',
                flexGrow: 1,
            }}>
                <Box m="10%">
                    {/* title & description */}
                    <Typography variant={isSmallScreen ? "h4" : "h2"} sx={{ fontFamily: 'Black Han Sans' }}>
                        커리어해커 알렉스<br /> 유튜브 채널
                    </Typography>
                    <Typography variant={isSmallScreen ? "caption" : "h6"}>
                        가장 쉽고, 가장 실용적인 <br />
                        총 180만 이상의 조회수를 기록한 <br />
                        커리어와 인공지능 콘텐츠들을 소개합니다<br />
                    </Typography>
                    {/* nav buttons */}
                    <Box mt={3} flexDirection="row" justifyContent="center" alignItems="center" flexGrow={1}>
                        <a href={"https://www.youtube.com/channel/UCdNqxwnqpXJpMt47WE7Bwwg"} target="_blank" rel="noopener noreferrer">
                            <Button color="success" variant="contained" onClick={() => log('main.youtube_channel_link_click')}>
                                <Typography variant={isSmallScreen ? "caption" : "subtitle2"} fontWeight={600}>
                                    유튜브 채널 보기
                                </Typography>
                            </Button>
                        </a>
                    </Box>
                </Box>
            </StyledSection>

            {/* Footer */}
            <Box sx={{ position: 'relative', bottom: 0, left: 0, right: 0, padding: 5, marginTop: 10 }} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <Typography variant="caption" sx={{ fontSize: '8px' }}>
                    © {new Date().getFullYear()} IPSUM AI LLC. All Rights Reserved.
                </Typography>
                <Box >
                    <Button component={Link} to={ROUTES.privacy.path} sx={{ fontSize: '8px', color: 'inherit' }}>
                        개인정보 처리방침
                    </Button>
                    <Button component={Link} to={ROUTES.terms.path} sx={{ fontSize: '8px', color: 'inherit' }}>
                        약관 및 조건
                    </Button>
                </Box>
            </Box>
        </StyledContainer >
    );
}

export default Main;