import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ROUTES from './utils/routes';
import MainPage from './pages/MainPage';
import Box from '@mui/material/Box';
import { CssBaseline } from '@mui/material';
import PromptGeneratorPage from './pages/PromptGeneratorPage';
import mytheme from './utils/theme';
import AuthProvider from './provider/AuthProvider';
import ProfilePage from './pages/ProfilePage';
import PromptExplorerPage from './pages/PromptExplorerPage';
import AdminPage from './pages/AdminPage';
import { PersonasProvider } from './context/PersonasContext';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import TermsAndConditionsPage from './pages/TermsAndConditionsPage';
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { analytics } from './config/firebase';

const theme = createTheme(mytheme);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <PersonasProvider>
          <Router>
            <Box component="main">
              <Box
                display="flex"
                flexDirection="column"
              >
                <Routes>
                  {/* main */}
                  <Route path={ROUTES.main.path} element={<MainPage />} />
                  {/* prompt_generator */}
                  <Route path={ROUTES.prompt_generator.path} element={<Outlet />}>
                    <Route index element={<PromptGeneratorPage />} />
                    <Route path={'*'} element={<Box p={3}><h3>페이지를 찾을 수 없습니다</h3></Box>} />
                  </Route>

                  {/* prompts_explorer */}
                  <Route path={ROUTES.prompt_explorer.path} element={<Outlet />}>
                    <Route index element={<PromptExplorerPage />} />
                    <Route path={'*'} element={<Box p={3}><h3>페이지를 찾을 수 없습니다</h3></Box>} />
                  </Route>

                  {/* profile */}
                  <Route path={ROUTES.profile.path} element={<Outlet />}>
                    <Route index element={<ProfilePage />} />
                    <Route path={'*'} element={<Box p={3}><h3>페이지를 찾을 수 없습니다</h3></Box>} />
                  </Route>

                  {/* admin */}
                  <Route path={ROUTES.admin.path} element={<Outlet />}>
                    <Route index element={<AdminPage />} />
                    <Route path={'*'} element={<Box p={3}><h3>페이지를 찾을 수 없습니다</h3></Box>} />
                  </Route>

                  {/* privacy policy */}
                  <Route path={ROUTES.privacy.path} element={<PrivacyPolicyPage />} />
                  {/* terms and conditions */}
                  <Route path={ROUTES.terms.path} element={<TermsAndConditionsPage />} />

                  {/* 404 */}
                  <Route path={'*'} element={<Box p={3}><h3>페이지를 찾을 수 없습니다</h3></Box>} />
                </Routes>
              </Box>
            </Box>
          </Router>
        </PersonasProvider>
      </AuthProvider>
    </ThemeProvider >
  );
}

export default App;