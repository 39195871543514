import { Box } from '@mui/system';
import React from 'react';

interface ScreenShotProps {
    src: string;
    alt: string;
    margin: number;
    width?: string;
    height?: string;
}

const VectorIcon: React.FC<ScreenShotProps> = ({ src, alt, margin, width, height }) => {
    const imageStyle: React.CSSProperties = {
        width: width || 'auto',
        maxWidth: '100%',
        height: height || 'auto',
        borderRadius: '10px', // You can adjust the border radius as needed
    };

    return (
        <Box sx={{ margin, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', overflow: 'hidden' }}>
            <img src={src} alt={alt} style={imageStyle} />
        </Box>
    );
};

export default VectorIcon;
