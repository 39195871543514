import React, { useEffect } from 'react';
import { Box, Typography, styled, Button, Avatar, Divider, Chip } from '@mui/material';
import TopBar from '../navigations/TopBar';
import { useAuth } from '../context/AuthContext';
import SignInComponent from '../components/profile/SignInComponent';
import { Link } from 'react-router-dom';

import ROUTES from '../utils/routes';
import { log } from '../config/firebase';
import UserInfoCard from '../components/profile/UserInfoCard';

const StyledContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    margin: 20,
    justifyContent: 'center',
    alignItems: 'center',
});

const StyledProfileHeader = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '20px',
    marginTop: '30px',
});

const StyledProfileInfo = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: "flex-start",
    alignItems: 'flex-start',
    padding: '20px',
    textAlign: 'left',
    width: '100%',
    maxWidth: '400px',
});

const ProfilePage: React.FC = () => {
    const { user, userInfo, isAuthenticated, logout } = useAuth();

    useEffect(() => {
        log('profile.page_view');
    }, [])

    if (user === null || !isAuthenticated) {
        return (
            <>
                <TopBar />
                <StyledContainer>
                    <SignInComponent />
                </StyledContainer>
            </>
        );
    }

    if (userInfo === null) {
        return (
            <>
                <TopBar />
                <UserInfoCard />
            </>
        )
    }

    return (
        <>
            <TopBar />
            <StyledContainer>
                <StyledProfileHeader>
                    <Avatar src={""} sx={{ width: 100, height: 100 }} />
                    <Typography variant="caption" p={1}>사진 변경하기</Typography>
                    <Box sx={{ backgroundColor: 'rgba(0,0,0,0.1)', padding: '2px', paddingLeft: '10px', paddingRight: '10px', borderRadius: '20px' }} textAlign="center" alignItems="center">
                        <Typography variant="caption" gutterBottom>
                            {user.email}
                        </Typography>
                    </Box>
                </StyledProfileHeader>
                <StyledProfileInfo>
                    <Box display="flex" justifyContent="space-between" alignItems="center" m={2}>
                        <Typography variant="subtitle1" width="80px">이름</Typography>
                        <Typography variant="body1" maxWidth="250px">{userInfo.username}</Typography>
                    </Box>
                    <Divider sx={{ width: "100%" }} />
                    <Box display="flex" justifyContent="space-between" alignItems="center" m={2}>
                        <Typography variant="subtitle1" width="80px">이메일</Typography>
                        <Typography variant="body1" maxWidth="250px">{user.email}</Typography>
                    </Box>
                    <Divider sx={{ width: "100%" }} />
                    <Box display="flex" justifyContent="space-between" alignItems="center" m={2}>
                        <Typography variant="subtitle1" width="80px">자기소개</Typography>
                        <Box sx={{ overflow: 'scroll' }}>
                            <Typography variant="body1" maxWidth="250px" maxHeight="300px" p={2}>{userInfo.bio}</Typography>
                        </Box>
                    </Box>
                    <Divider sx={{ width: "100%" }} />
                    {
                        userInfo.role !== 'USER' ?
                            <>
                                <Box display="flex" justifyContent="space-between" alignItems="center" m={2}>
                                    <Typography variant="subtitle1" width="80px">권한</Typography>
                                    <Chip
                                        sx={{ cursor: 'pointer' }}
                                        label={"관리자"}
                                        variant="outlined"
                                        component={Link}
                                        to={ROUTES.admin.path}
                                    />
                                </Box>
                                <Divider sx={{ width: "100%" }} />
                            </>
                            : null
                    }
                </StyledProfileInfo>
                <Box>
                    <Box m={2}>
                        <Button fullWidth>비밀번호 변경</Button>
                    </Box>
                    <Box m={2}>
                        <Button onClick={logout} fullWidth>로그아웃</Button>
                    </Box>
                </Box>
            </StyledContainer>
        </>
    );
};

export default ProfilePage;
