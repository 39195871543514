const ROUTES = {
    main: {
        path: '/',
    },
    profile: {
        path: '/profile',
    },
    prompt_generator: {
        path: '/prompt_generator',
    },
    prompt_explorer: {
        path: '/prompt_explorer',
    },
    user: {
        path: '/user',
    },
    admin: {
        path: '/admin',
    },
    privacy: {
        path: '/privacy',
    },
    terms: {
        path: '/terms',
    }
}

export default ROUTES;