
export const generatePrompt = async (
    data: string,
    setResponseData: React.Dispatch<React.SetStateAction<string>>,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
): Promise<void> => {
    setIsLoading(true);

    try {
        const response = await fetch(`${process.env.REACT_APP_API_ADDRESS}/api/generatePrompts`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ data }),
        });

        if (!response.ok) {
            throw new Error(`Request failed with status ${response.status}`);
        }

        // Assuming the server responds with a streaming format like NDJSON
        // Create a reader from the response stream
        const reader = response.body?.getReader();
        if (!reader) {
            throw new Error("Failed to get a stream reader");
        }

        // Use a TextDecoder to process chunks
        const decoder = new TextDecoder("utf-8");
        let result = '';

        // Loop to process each streamed chunk
        while (true) {
            const { done, value } = await reader.read();
            if (done) {
                setIsLoading(false);
                console.log('final prompt:', result);
                break;
            }
            // Massage and parse the chunk of data
            const chunk = decoder.decode(value);
            result += chunk;
            setResponseData(result);
        }

        // After loop: All data has been read, update the state
    } catch (error) {
        console.error("Error while generating prompt:", error);
        if (error instanceof Error) {
            // Provide specific error feedback if needed
            throw new Error(error.message);
        }
    } finally {
        // Ensure loading state is updated regardless of success or failure
        setIsLoading(false);
    }
};

