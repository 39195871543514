import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getStorage, ref, getDownloadURL } from 'firebase/storage';

export const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: "career-hacker-alex.firebaseapp.com",
    projectId: "career-hacker-alex",
    storageBucket: "career-hacker-alex.appspot.com",
    messagingSenderId: "334713357524",
    appId: "1:334713357524:web:19f0515877de9aef2fa741",
    measurementId: process.env.REACT_APP_GOOGLE_ANALYTICS_ID
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);
const storage = getStorage();

const log = (eventName: string, eventParams?: { [key: string]: any; }) => {
    if (process.env.NODE_ENV === 'development') {
        console.log(eventName, eventParams);
        return;
    }
    logEvent(analytics, eventName, eventParams);
}

export { app, auth, analytics, storage, ref, getDownloadURL, log };